import React, { useEffect, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import Link from 'gatsby-link'

export interface BreadcrumbItem {
  href: Maybe<string>
  name: Maybe<string>
}

export interface BreadcrumbProps {
  breadcrumb: Maybe<Array<Maybe<{ href: Maybe<string>; name: Maybe<string> }>>>
  type: 'PRODUCT' | 'SEARCH' | 'CATEGORY'
  brand?: Maybe<string>
  typeOfPage?: 'subcategoryPage' | 'collectionPage' | string
  hasBanner?: boolean
}

const caretSvgProps = {
  width: '10',
  height: '10',
  viewBox: '0 0 20 20',
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  breadcrumb: breadcrumbs,
  type,
  typeOfPage,
  brand,
  hasBanner,
}) => {
  const [brandLink, setBrandLink] = useState('')
  const pageCss = typeOfPage ?? null

  useEffect(() => {
    setBrandLink(
      brand === '300 km/h'
        ? '/300-km'
        : `/${brand?.toLowerCase().replaceAll(' ', '-')}`
    )
  }, [brand])

  return (
    <Flex
      data-testid="breadcrumb"
      variant={`breadcrumb.${pageCss}.container`}
      className="breadcrumb"
    >
      <Link to="/" aria-label="Home" style={{ textDecoration: 'none' }}>
        <Text
          as="p"
          className={hasBanner ? 'whiteLink' : 'grayLink'}
          variant={`breadcrumb.${pageCss}.homeLayer`}
        >
          Home
        </Text>
      </Link>
      {breadcrumbs?.map((breadcrumb, i) => (
        <Flex
          key={`navigation-item-${i}`}
          variant={`breadcrumb.${pageCss}.pair`}
        >
          <Flex
            as="svg"
            className={hasBanner ? 'whiteIcon' : 'grayIcon'}
            variant={`breadcrumb.${pageCss}.caretIcon`}
            {...caretSvgProps}
          >
            <path d="M5.4 21L4.1 19.5 11.7 12 4.1 4.5 5.5 3 14.6 12z" />
            <path d="M11.5 20.9L10 19.6 17.4 12 9.9 4.5 11.3 3 20.2 12z" />
          </Flex>
          <a
            href={
              type === 'SEARCH'
                ? `/s/${breadcrumb?.href}`
                    .replace('//', '/')
                    .replace('?map=ft', '?map=term')
                : breadcrumb?.href ?? ''
            }
            style={{ textDecoration: 'none', textTransform: 'capitalize' }}
          >
            <Text
              as="p"
              className={hasBanner ? 'whiteLink' : 'grayLink'}
              variant={`breadcrumb.${pageCss}.${
                i === breadcrumbs.length - 1 && type === 'SEARCH'
                  ? 'last'
                  : 'middle'
              }`}
            >
              {breadcrumb?.name}
            </Text>
          </a>
        </Flex>
      ))}
      {brand !== 'Avon' && (
        <Flex variant={`breadcrumb.${pageCss}.pair`}>
          <Flex
            as="svg"
            className={hasBanner ? 'whiteIcon' : 'grayIcon'}
            variant={`breadcrumb.${pageCss}.caretIcon`}
            {...caretSvgProps}
          >
            <path d="M5.4 21L4.1 19.5 11.7 12 4.1 4.5 5.5 3 14.6 12z" />
            <path d="M11.5 20.9L10 19.6 17.4 12 9.9 4.5 11.3 3 20.2 12z" />
          </Flex>
          <a
            href={
              type === 'SEARCH'
                ? `/s/${brandLink}?map=term`
                    .replace('//', '/')
                    .replace('?map=ft', '?map=term')
                : brandLink
            }
            style={{ textDecoration: 'none', textTransform: 'capitalize' }}
          >
            <Text
              as="p"
              className={hasBanner ? 'whiteLink' : 'grayLink'}
              variant={`breadcrumb.${pageCss}.${'last'}`}
            >
              {brand}
            </Text>
          </a>
        </Flex>
      )}
    </Flex>
  )
}
