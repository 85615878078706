import { useProfile } from '@vtex/gatsby-theme-store'
import { useEffect } from 'react'
import type { SearchParamsState } from '@vtex/store-sdk'
import type { FC } from 'react'

interface EventProps {
  searchParams: SearchParamsState
}

const EventSearch: FC<EventProps> = ({ searchParams }) => {
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    const { dataLayer } = window

    const data = {
      event: 'avon:search',
      pageCategory: 'search',
      pageUrl: window.location.href,
      visitorLoginState: isAuthenticated,
    }

    setTimeout(() => {
      dataLayer.push(data)
    }, 1000)
  }, [isAuthenticated, searchParams])

  return null
}

export default EventSearch
