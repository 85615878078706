/** @jsx jsx */
import type { FC } from 'react'
import { Fragment } from 'react'
import { Container, Flex, jsx, Text } from '@vtex/store-ui'
import type { BreadcrumbItem } from '@vtex/store-ui'
import SearchBanner from 'src/components/search/Banner'
import FacetedProductList from 'src/components/common/FacetedProductList'

import { Breadcrumb } from '../../../components/common/Breadcrumb'
import type { SearchViewProps } from '..'

type Props = SearchViewProps

const AboveTheFold: FC<Props> = ({
  data,
  data: {
    vtex: { banners, productSearch },
  },
}) => {
  const breadcrumb = (data.vtex.facets?.breadcrumb ?? []) as BreadcrumbItem[]

  return (
    <Container>
      {productSearch.recordsFiltered > 0 ? (
        <Fragment>
          <Breadcrumb breadcrumb={breadcrumb} type="SEARCH" />

          <SearchBanner html={banners?.banners?.[0]?.html} />

          <FacetedProductList data={data} />
        </Fragment>
      ) : (
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '30px',
            fontWeight: 'bolder',
            textAlign: 'center',
          }}
        >
          <Text>:(</Text>
          <Text>
            Oops! Não encontramos nenhum <br /> resultado para a sua busca.
          </Text>
        </Flex>
      )}
    </Container>
  )
}

export default AboveTheFold
